var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "md",
        title: _vm.$t("payments.reasonCancel.confirmation"),
      },
      on: { show: _vm.verifyIsMexico, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c(
        "b-container",
        { staticClass: "p-0" },
        [
          _c("h4", [
            _vm._v(
              _vm._s(_vm.$t("payments.reasonCancel.cancellationTitle")) + ":"
            ),
          ]),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-radio-group", {
                    staticClass: "pl-2",
                    attrs: {
                      options: _vm.options,
                      name: "cancelOptions",
                      stacked: "",
                    },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "float-right pt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { "data-test": "modal-transaction__btn-save" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModalCancelTransaction")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("payments.buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: {
                    variant: "primary",
                    "data-test": "modal-transaction__btn-save",
                    disabled: !_vm.selected || _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.cancelTransaction()
                    },
                  },
                },
                [
                  _vm.loading
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("payments.buttons.confirming")) +
                            " "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("payments.buttons.confirm")) + " "
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }